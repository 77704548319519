import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Link, type MetaFunction } from "@remix-run/react";
import { AlertCircle, ArrowLeft, ArrowUpCircle } from "lucide-react";

export const meta: MetaFunction = () => {
    return [{ title: "Error | TailoredCV.ai" }];
};

interface ErrorPageProps {
    title?: string;
    message?: string;
    backAction?: () => void;
    showRetry?: boolean;
}

export default function ErrorPage({
    title = "An error occurred",
    message = "We're sorry, but something went wrong. Please try again or contact support if the problem persists.",
    backAction = () => window.history.back(),
    showRetry = false,
}: ErrorPageProps = {}) {
    return (
        <div className="min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8">
            <Card className="w-full max-w-md">
                <CardHeader className="text-center">
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                        <AlertCircle className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <CardTitle className="mt-3 text-xl font-semibold text-gray-900">
                        {title}
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <p className="text-center text-sm text-gray-600">{message}</p>
                </CardContent>
                <CardFooter className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
                    <Button variant="outline" className="w-full sm:w-auto" onClick={backAction}>
                        <ArrowLeft className="mr-2 h-4 w-4" />
                        Go Back
                    </Button>
                    {showRetry && (
                        <Button
                            variant="outline"
                            className="w-full sm:w-auto"
                            asChild
                            onClick={() => window.location.reload()}
                        >
                            <ArrowUpCircle className="mr-2 h-4 w-4" />
                            Try Again
                        </Button>
                    )}
                </CardFooter>
                <div className="mt-4 mb-4 text-center text-xs text-gray-500">
                    <p>If you continue to experience issues, please contact our support team.</p>
                    <Link to="/support" className="text-primary hover:underline">
                        Contact Support
                    </Link>
                </div>
            </Card>
        </div>
    );
}
